import React, { useState, useEffect } from "react";
import "./App.css";
//import "./bootstrap.min.css";
// imported for routing
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// SCREENS
import Home from "./Screens/Home";
import NewStudent from "./Screens/NewStudent";
import Password from "./Screens/Password";
import Reconciliation from "./Screens/Reconciliation";
import Withdrawl from "./Screens/Withdrawl";
import PasswordReconciliation from "./Screens/PasswordReconciliation";
import Login from "./Screens/Login";
// APIs that will be called as soon as the application is loaded
import {
  getUnitsAction,
  getProvincesAction,
  getTotalInstDaysNoAction,
} from "./Store/Actions/addStudentFormDataActions";
// MSAL imports
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
// Config for MS Graph API
import { callMsGraph } from "./Config/graph";
// Config for MS Login API
import { loginRequest } from "./Config/AzureLogin";
// Constants
import {
  UserProfileLocalStorage,
  TokenSessionStorage,
} from "./Utilities/Constants";
import ReEnrollStudent from "./Screens/ReEnroll";

// THIS APP IS USING MSAL TO LOG USER IN
// MSAL PROVIDES 2 COMPONENTS FOR AUTHENTICATED AND UNAUTHENTICATED USERS
// BASIC CONFIGURATION IS PRESENT IN THE CONFIG FOLDER
// THE APP IS WRAPPED IN MSAL PROVIDER IN THE index.tsx FILE

function App() {
  // MSAL's Hook is returning accounts and instance
  const { instance, accounts } = useMsal();
  const [isTokenReceived, setIsTokenReceived] = useState<boolean>(false);

  // Calling the Get Token API
  const getToken = (request: any) => {
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setIsTokenReceived(true);
        sessionStorage.setItem(TokenSessionStorage, response.accessToken);
      })
      .catch((error) => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then((response) => {
            setIsTokenReceived(true);
            sessionStorage.setItem(TokenSessionStorage, response.accessToken);
          });
        }
      });
  };

  const getGraphData = (request: any) => {
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        callMsGraph(response.accessToken).then((response: any) =>
          // Saving response of graph API in LS
          localStorage.setItem(
            UserProfileLocalStorage,
            JSON.stringify(response ?? {})
          )
        );
      })
      .catch((e) => {
        instance
          .acquireTokenPopup({
            ...loginRequest,
            account: accounts[0],
          })
          .then((response: any) => {
            callMsGraph(response.accessToken).then((response: any) =>
              // Saving response of graph API in LS
              localStorage.setItem(
                UserProfileLocalStorage,
                JSON.stringify(response ?? {})
              )
            );
          });
      });
  };

  useEffect(() => {
    // Calling MS Graph API & && token API if user is authemticated
    if (accounts.length) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      getToken(request);
      getGraphData(request);
    }
  }, [accounts, instance]);

  // Calling units and provinces APIs
  useEffect(() => {
    if (isTokenReceived) {
      getUnitsAction();
      getProvincesAction();
      getTotalInstDaysNoAction();
    }
  }, [isTokenReceived]);

  return (
    <>
      {/* ALL ROUTES WILL BE ACCESSIBLE ONLY WHEN USER IS AUTHENTICATED */}
      <AuthenticatedTemplate>
        <BrowserRouter>
          <Routes>
            <Route path="/Home" element={<Home />} />
            <Route path="/NewStudent/:id" element={<NewStudent />} />
            <Route path="/Reconciliation/" element={<Reconciliation />} />
            <Route path="/Withdrawl/" element={<Withdrawl />} />
            <Route path="/Password/:id" element={<Password />} />
            <Route path="/reenroll/:id" element={<ReEnrollStudent />} />
            <Route
              path="/PasswordReconciliation/:id/:studentName/:cbeNo"
              element={<PasswordReconciliation />}
            />
            {/* HOME IS SET AS THE DEFAULT ROUTE FOR THE AUTHENTICATED USER */}
            <Route path="*" element={<Navigate to="/home" replace={true} />} />
          </Routes>
        </BrowserRouter>
      </AuthenticatedTemplate>
      {/* ONLY LOGIN PAGE WILL BE VISIBLE FOR THE UNAUTHENTICATED USER AND THAT WOULD ALSO BE THE DEFAULT PAGE */}
      <UnauthenticatedTemplate>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/reenroll/:id" element={<Login />} />
            <Route path="*" element={<Navigate to="/" replace={true} />} />
          </Routes>
        </BrowserRouter>
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
