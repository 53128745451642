import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col, Spinner } from "react-bootstrap";
import Toast from "../Components/Toast";
import Layout from "../Layout/Index";
import { useParams } from "react-router";
import { FaRegCopy, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import {
  NewStudentPwdLocalStorage,
  NewStudentUsernameLocalStorage,
  ShowPasswordLocalStorage,
} from "../Utilities/Constants";

const Password = () => {
  const [showToast, setShowToast] = useState(false);
  const [displayPasswordSection, setDisplayPasswordSection] =
    useState<any>(null);
  // STATE TO SHOW/HIDE PASSWORD
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState<any>({});
  // GETTING USER ID FROM PARAMS
  const { id } = useParams();
  // BREADCRUMB FOR LAYOUT
  const breadcrumb: any = [
    { title: "Home", link: "/home" },
    { title: "Password", link: `/Password/${id}` },
  ];
  // FOR NOW THESE KEYS ARE HARDCODED, IN FUTURE WE WILL GET THEM USING APIs
  const mask = "* * * * * * * * * * * *";
  // FUNCTION TO COPY TO CLIPBOARD
  const handleCopyPassword = () => {
    navigator.clipboard.writeText(password);
    setShowToast(true);
    setMessage({
      variant: "success",
      heading: "Notification",
      body: "Password copied!",
    });
  };
  const togglePassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    debugger;
    const showPASS: any = localStorage.getItem(ShowPasswordLocalStorage);
    setDisplayPasswordSection(showPASS);
    const passwordLS: any = localStorage.getItem(NewStudentPwdLocalStorage);
    setPassword(passwordLS);
    const usernameLS: any = localStorage.getItem(
      NewStudentUsernameLocalStorage
    );
    setUsername(usernameLS);
  }, [displayPasswordSection]);

  useEffect(() => {
    setShowToast(true);
    setMessage({
      heading: "Notification",
      body: "Student Added Successfully!",
    });
  }, []);

  return (
    <Layout breadcrumList={breadcrumb} pageTitle="Password" IconNo="1">
      <div className="pt-5">
        {displayPasswordSection === "Y" ? (
          <Row className="p-2 d-flex justify-content-center my-5 py-5">
            <h3 className="text-center mt-5 pt-5">
              A new password was created for {username}
            </h3>
            <Col className="col-lg-4 col-sm-12 d-flex mb-5 pb-5">
              <Col className="border d-flex justify-content-center pt-2 rounded">
                <Form.Label>{showPassword ? password : mask}</Form.Label>
              </Col>
              <Button
                variant="secondary"
                className="mx-2"
                onClick={() => togglePassword()}
              >
                {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </Button>
              <Button variant="secondary" onClick={() => handleCopyPassword()}>
                <FaRegCopy />
              </Button>
            </Col>
          </Row>
        ) : displayPasswordSection === "N" ? (
          <Row className="p-2 d-flex justify-content-center my-5 py-5">
            <h3 className="text-center mt-5 pt-5">
              A new password was created for {username}
            </h3>
            <Col className="col-lg-4 col-sm-12 d-flex mb-5 pb-5">
              <Col className="border d-flex justify-content-center pt-2 rounded">
                <Form.Label>{showPassword ? password : mask}</Form.Label>
              </Col>
              <Button
                variant="secondary"
                className="mx-2"
                onClick={() => togglePassword()}
              >
                {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </Button>
              <Button variant="secondary" onClick={() => handleCopyPassword()}>
                <FaRegCopy />
              </Button>
            </Col>
            {/* <Col className="col-lg-12 mb-5 pb-5 text-center">
              WV account's password can be changed via Student Tool. Please
              contact with CBE Service Desk if you must change the password
            </Col> */}
          </Row>
        ) : (
          <Row className="p-2 d-flex justify-content-center my-5 py-5">
            <Col className="col-lg-1 d-flex mb-5 pb-5">
              <Spinner
                animation="border"
                style={{ height: 20, width: 20 }}
                className="mx-2"
                variant="info"
              />
            </Col>
          </Row>
        )}
      </div>
      <Toast
        showToast={showToast}
        setShowToast={setShowToast}
        message={message}
      />
    </Layout>
  );
};

export default Password;
