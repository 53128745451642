import axiosInstance from "../Constants/Instance";
import { postRegisterStudentApi } from "../Constants/BaseURL";
import {
  NewStudentPwdLocalStorage,
  NewStudentUsernameLocalStorage,
} from "../../Utilities/Constants";
import { ShowPasswordLocalStorage } from "../../Utilities/Constants";

export const registerStudentAction = async (
  studentObj: any,
  setRegisterStudentLoading: any,
  setRegisterStudentError: any,
  navigation: any
) => {
  try {
    const { data } = await axiosInstance.post(
      postRegisterStudentApi,
      studentObj,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );
    if (data) {
      localStorage.setItem(
        ShowPasswordLocalStorage,
        studentObj.cbeNo || studentObj.ablNo ? "Y" : "N"
      );
      localStorage.setItem(NewStudentPwdLocalStorage, data.password);
      localStorage.setItem(NewStudentUsernameLocalStorage, data.username);
      navigation(`/Password/${data.username ? data.username : "0"}`);
    } else {
      setRegisterStudentError("NoItem");
    }
    setRegisterStudentLoading(false);
  } catch (e) {
    setRegisterStudentLoading(false);
    setRegisterStudentError("Something went wrong. Please try again later");
  }
};
