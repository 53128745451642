import axiosInstance from "../Constants/Instance";
import { searchWVStudentApi } from "../Constants/BaseURL";

// MANAGING THE LOADING AND ERROR STATES IN THE ACTION AS WELL
export const searchWVStudentAction = async (
  { cbeNo, asnNo }: any,
  setStudentList: any,
  setSearchLoading: any,
  setSearchResultError: any
) => {
  try {
    const url = `${searchWVStudentApi}?${cbeNo ? "&cbeNo=" + cbeNo : ""}${
      asnNo ? "&asnNo=" + asnNo : ""
    }`;

    const { data } = await axiosInstance.get(url);
    if (data && Array.isArray(data) && data.length) {
      setStudentList(data);
    } else setSearchResultError("No user found.");
    setSearchLoading(false);
  } catch (e) {
    setSearchLoading(false);
    setSearchResultError("Something went wrong. Please try again later");
  }
};
