import { useState, useEffect } from "react";
import Layout from "../Layout/Index";
import { IStudent } from "../Models/IStudents";
import Spinner from "react-bootstrap/Spinner";
import {
  getStudentById,
  reEnrollStudentAction,
} from "../Store/Actions/reEnrollActions";
import ErrorComponent from "../Components/ErrorComponent";
import { Container, Card, Button, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";

const ReEnrollStudent = () => {
  const [student, setStudent] = useState<IStudent>();
  // LOADING/ERROR STATES FOR GET_STD_BY_ID
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  // LOADING/ERROR STATES FOR RE-ENROLL API
  const [enrolLoading, setEnrolLoading] = useState(false);
  const [enrolError, setEnrolError] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();
  // BREADCRUMB FOR LAYOUT
  const breadcrumb: any = [
    { title: "Home", link: "/home" },
    { title: "Re-enrollment", link: "/reenrol/" + id },
  ];

  const handleReEnrollStudent = () => {
    setEnrolLoading(true);
    setEnrolError("");
    if (student)
      reEnrollStudentAction(student, navigate, setEnrolError, setEnrolLoading);
  };

  useEffect(() => {
    if (id) getStudentById(id, setStudent, setError, setLoading);
  }, [id]);

  return (
    <Layout
      breadcrumList={breadcrumb}
      pageTitle="Student Re-enrollment"
      IconNo="2"
    >
      <Container className="mt-2">
        {loading ? (
          <div className="d-flex justify-content-center my-3 py-3">
            <Spinner animation="border" variant="info" />{" "}
          </div>
        ) : error ? (
          <ErrorComponent
            errorTitle="Error"
            errorMessage={error}
            Btn1text="Retry"
            Btn1Action={() => window.location.reload()}
            Btn2text="Cancel"
            Btn2Action={() => navigate("/home")}
          />
        ) : (
          <Card>
            {student?.activeFlag === "A" ? (
              <Card.Header className="bgBlue50 colorNavy">
                {student?.firstName} {student?.lastName} is already enrolled.
              </Card.Header>
            ) : (
              <>
                <Card.Header className="bgBlue50 colorNavy">
                  Re-Enroll Student
                </Card.Header>
                <Card.Text className="colorNavy m-3">
                  Are you sure you want to re-enroll {student?.firstName}{" "}
                  {student?.lastName}?
                </Card.Text>
                {enrolError ? (
                  <Card.Text className="colorRed mx-3 mb-3">
                    {enrolError}
                  </Card.Text>
                ) : (
                  <></>
                )}
                <Row className="mb-2 mx-1">
                  <Col className="col-lg-2">
                    <Button
                      onClick={() => handleReEnrollStudent()}
                      variant="primary"
                      className="w-100"
                    >
                      {enrolLoading ? (
                        <Spinner
                          variant="info"
                          animation="border"
                          style={{ height: 20, width: 20 }}
                          className="mx-2"
                        />
                      ) : (
                        <>Yes</>
                      )}
                    </Button>
                  </Col>
                  <Col className="col-lg-2">
                    <Button
                      variant="primary"
                      onClick={() =>
                        (window.location.href = `${process.env.REACT_APP_Stars1Url}`)
                      }
                      className="w-100"
                    >
                      No
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Card>
        )}
      </Container>
    </Layout>
  );
};

export default ReEnrollStudent;
