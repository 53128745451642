import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/esm/Form";
import Button from "react-bootstrap/esm/Button";
import Toast from "../Components/Toast";
import Layout from "../Layout/Index";
import { useParams } from "react-router";
import { FaRegCopy, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { ReconciliationPwdLocalStorage } from "../Utilities/Constants";

const PasswordReconciliation = () => {
  const [showToast, setShowToast] = useState(false);
  // STATE TO SHOW/HIDE PASSWORD
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState<any>({});
  // GETTING USER ID FROM PARAMS
  const { id, studentName, cbeNo } = useParams();
  // BREADCRUMB FOR LAYOUT
  const breadcrumb: any = [
    { title: "Home", link: "/home" },
    { title: "Password", link: `/Password/${id}` },
  ];
  // FOR NOW THESE KEYS ARE HARDCODED, IN FUTURE WE WILL GET THEM USING APIs
  const mask = "* * * * * * * * * * * *";
  // FUNCTION TO COPY TO CLIPBOARD
  const handleCopyPassword = () => {
    navigator.clipboard.writeText(password);
    setShowToast(true);
    setMessage({
      variant: "success",
      body: "Password copied!",
    });
  };
  const togglePassword = () => setShowPassword(!showPassword);

  React.useEffect(() => {
    const passwordLS: any = localStorage.getItem(ReconciliationPwdLocalStorage);
    setPassword(passwordLS);
  }, []);
  return (
    <Layout breadcrumList={breadcrumb} pageTitle="Reconciled" IconNo="1">
      <div className="pt-5">
        <Row className="p-2 d-flex justify-content-center my-5 py-5">
          <h3 className="text-center mt-5 pt-5">
            Thank you for approving this record. {studentName} account is
            renamed to {cbeNo}.
          </h3>
        </Row>
      </div>
      <Toast
        showToast={showToast}
        setShowToast={setShowToast}
        message={message}
      />
    </Layout>
  );
};

export default PasswordReconciliation;
