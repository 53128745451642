import { useState } from "react";
import { Container, Row, Col, Card, Form, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/esm/Button";

const SearchFormWithdrawl = ({
  searchWithdrawlParameters,
  searchQuery,
  searchStudentWithdrawl,
  setSearchQuery,
  loading,
}: any) => {
  const [error, setError] = useState<string>("");
  const handleSearch = () => {
    if (!searchQuery.cbeNo && !searchQuery.asnNo) {
      setError("Either CBE # or ASN # must be selected.");
      return;
    }
    searchStudentWithdrawl();
  };

  return (
    <Container>
      <Card>
        <Card.Header className="bgBlue50 colorNavy">
          Student withdrawal
        </Card.Header>
        <Form className="p-2">
          <Row>
            {searchWithdrawlParameters.map((p: any, index: any) => {
              return (
                <Col key={index} className="col-sm-12 col-lg-4 mb-2">
                  <Form.Group className="mb-2" controlId="formBasicCBEID">
                    <Form.Label>
                      {p.Label}
                      {p.required ? " *" : ""}
                    </Form.Label>
                    <Form.Control
                      name={p.Value}
                      type="text"
                      placeholder={p.PlaceHolder}
                      onChange={(event) => {
                        let temp = searchQuery;
                        temp[event.target.name] = event.target.value;
                        setError("");
                        setSearchQuery(temp);
                      }}
                    />
                  </Form.Group>
                </Col>
              );
            })}
            <Col className="d-flex align-items-end mb-3">
              <Button
                variant="primary"
                onClick={() => handleSearch()}
                type="button"
                className="w-50"
              >
                {loading ? (
                  <Spinner
                    variant="info"
                    animation="border"
                    style={{ height: 20, width: 20 }}
                    className="mx-2"
                  />
                ) : (
                  <>Next</>
                )}
              </Button>
            </Col>
          </Row>
        </Form>
        <Row className="p-2">
          <Col>
            <p className="text-danger">{error}</p>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default SearchFormWithdrawl;
