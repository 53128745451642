import axiosInstance from "../Constants/Instance";
import {
  UnitsLocalStorage,
  PrivincesLocalStorage,
  TotalInstDaysNoLocalStorage,
} from "../../Utilities/Constants";
import {
  getUnitsApi,
  totalInstDaysNo,
  getPrivinces,
  getCities,
} from "../Constants/BaseURL";

// THIS ACTION WILL BE CALLED AS SOON AS THE APPLICATION IS LOADED AND ITS RESPONSE IS STORED IN LS SO THAT WE CAN USE IT IN NEW STUDENT PAGE
export const getProvincesAction = async () => {
  try {
    const { data } = await axiosInstance.get(getPrivinces);
    localStorage.setItem(PrivincesLocalStorage, JSON.stringify(data));
  } catch (e) {}
};

export const getCitiesAction = async (province: any, setCities: any) => {
  try {
    const { data } = await axiosInstance.get(getCities + province);
    if (data && Array.isArray(data) && data.length) setCities(data);
  } catch (e) {}
};

// THIS ACTION WILL BE CALLED AS SOON AS THE APPLICATION IS LOADED AND ITS RESPONSE IS STORED IN LS SO THAT WE CAN USE IT IN NEW STUDENT PAGE
export const getUnitsAction = async () => {
  try {
    const { data } = await axiosInstance.get(getUnitsApi);
    localStorage.setItem(UnitsLocalStorage, JSON.stringify(data));
  } catch (e) {}
};

// THIS ACTION WILL BE CALLED AS SOON AS THE APPLICATION IS LOADED AND ITS RESPONSE IS STORED IN LS SO THAT WE CAN USE IT IN NEW STUDENT PAGE
export const getTotalInstDaysNoAction = async () => {
  try {
    const { data } = await axiosInstance.get(totalInstDaysNo);
    localStorage.setItem(TotalInstDaysNoLocalStorage, JSON.stringify(data));
  } catch (e) {}
};
