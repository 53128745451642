import React from "react";
import { Button, Container, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/esm/Card";
import { IStudent } from "../Models/IStudents";
import { useNavigate } from "react-router";
import { NewStudentLocalStorage } from "../Utilities/Constants";

interface ISearchResults {
  studentList: IStudent[];
}

const SearchResult = ({ studentList }: ISearchResults) => {
  const navigation = useNavigate(); //cannot use instance direcly

  const selectStudent = (student: any) => {
    localStorage.setItem(NewStudentLocalStorage, JSON.stringify(student));
    navigation(`/NewStudent/${student?.studentId}`);
  };

  return (
    <Container className="my-2">
      <Card>
        <Card.Header className="bgBlue50 colorNavy">Search Results</Card.Header>
        <Card.Text className="colorNavy m-1 mx-3">
          The following active CBE student(s) was/were found to match the
          provided information.  Select the student to be moved to Westview. 
          Note that once the student is enrolled, the account will immediately
          be restricted according to Westview's protocol.
        </Card.Text>
        <Table bordered hover>
          <thead>
            <tr>
              <th>CBE No</th>
              <th>Student ID</th>
              <th>ASN</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>DOB</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {studentList.map((item: any, index: any) => {
              return (
                <tr key={index}>
                  <td>{item.cbeNo}</td>
                  <td>{item.studentId}</td>
                  <td>{item.ablNo}</td>
                  <td>{item.firstName}</td>
                  <td>{item.lastName}</td>
                  <td>{item.dob}</td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() => selectStudent(item)}
                    >
                      Next
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    </Container>
  );
};

export default SearchResult;
