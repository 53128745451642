// BASE URL OF THE APP
//export const url = "https://starsdevha.azurewebsites.net/";
export const url = process.env.REACT_APP_baseUrl;
//export const url = "https://localhost:7283/";

// SWAGGER
// https://starsdevha.azurewebsites.net/swagger/index.html

// URLs FOR DIFFERENT ROUTES
export const searchStudentApi = url + "api/SearchSDSStudents/";

export const getUnitsApi = url + "api/Units/";

export const getPrivinces = url + "api/ProvinceInfo/";

export const getCities = url + "api/CitiesList/";

export const postRegisterStudentApi = url + "api/Students/";

export const searchWVStudentApi = url + "api/Students/";

export const withDrawStudent = url + "api/WithdrawStudent/";

export const reconciliation = url + "api/reconsiliation/";

export const reconciliationResult = url + "api/ReconsilationResult/";

export const totalInstDaysNo = url + "api/TotalInstDaysNo/";

export const studentById = url + "api/StudentById/";

export const reEnrolStudent = url + "api/ReEnrollStudent";

export default url;
