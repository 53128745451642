import { Button, Card } from "react-bootstrap";
import Layout from "../Layout/Index";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../Config/AzureLogin";
import React from "react";
import { useLocation, useNavigate } from "react-router";

const Login = () => {
  const { instance } = useMsal();
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogin = (loginType: string) => {
    // LOGIN FUNCTION
    if (loginType === "redirect") {
      console.log(loginRequest);
      instance
        .loginRedirect(loginRequest)
        .then(() => {
          const path = localStorage.getItem("redirectOnSuccessPath");
          if (path) {
            navigate(path);
            localStorage.removeItem("redirectOnSuccessPath");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  React.useEffect(() => {
    const path = location.pathname;
    if (path !== "/") localStorage.setItem("redirectOnSuccessPath", path);
  }, []);

  return (
    <Layout isBreadCrumHidden={true} isOptionsHidden={true}>
      <Card className="p-5">
        <h3 className="text-center">
          Welcome to Calgary Board of Education's Star's Admin site.
        </h3>
        <Card.Title className="text-center">
          <Button onClick={() => handleLogin("redirect")}>Sign In</Button>
        </Card.Title>
      </Card>
    </Layout>
  );
};

export default Login;
