import axiosInstance from "../Constants/Instance";
import { reconciliation, reconciliationResult } from "../Constants/BaseURL";
import { ReconciliationPwdLocalStorage } from "../../Utilities/Constants";

// THIS ACTION WILL BE CALLED AS SOON AS THE RECONCILIATION PAGE IS CLICKED
export const getReconciliations = async (setList: any, setloading: any) => {
  try {
    const { data } = await axiosInstance.get(reconciliation);
    if (data && Array.isArray(data)) setList(data);
    setloading(false);
  } catch (e) {
    setloading(false);
    console.log(e);
  }
};

// THIS ACTION WILL BE CALLED TO APPROVE/DISAPPROVE STUDENT RECONCILIATION
export const saveReconciliation = async (
  id: string,
  approved: boolean,
  navigation: any,
  StudentName: any,
  CBEAccountNo: any
) => {
  try {
    const { data } = await axiosInstance.put(reconciliationResult + id, {
      approved,
    });
    if (approved) {
      localStorage.setItem(ReconciliationPwdLocalStorage, data.password);
      navigation(
        `/PasswordReconciliation/${id}/${StudentName}/${CBEAccountNo}`
      );
    }
  } catch (e) {
    console.log(e);
  }
};
