import React from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router";

const CbeStudentCheck = ({ decideIfCbe }: any) => {
  const navigate = useNavigate();
  return (
    <Container className="mt-2">
      <Card>
        <Card.Header className="bgBlue50 colorNavy">CBE Student</Card.Header>
        <Card.Text className="colorNavy m-3">
          Is the student you are registering an existing CBE student?
        </Card.Text>
        <Row className="mb-2 mx-1">
          <Col className="col-lg-2">
            <Button
              onClick={() => decideIfCbe()}
              variant="primary"
              className="w-100"
            >
              Yes
            </Button>
          </Col>
          <Col className="col-lg-2">
            <Button
              variant="primary"
              onClick={() => navigate("/newstudent/0")}
              className="w-100"
            >
              No
            </Button>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default CbeStudentCheck;
