import React from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

const Toasts = ({ showToast, setShowToast, message }: any) => {
  return (
    <ToastContainer position="bottom-start" className="position-fixed m-5">
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={2000}
        autohide
        // bg={message?.variant ? message?.variant : "danger"}
      >
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">
            {message?.heading ? message?.heading : ""}
          </strong>
        </Toast.Header>
        <Toast.Body>
          {message?.body ? message?.body : "Something went wrong!"}
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default Toasts;
