import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";

const GenericErrorComponent = ({
  errorTitle,
  errorMessage,
  Btn1text,
  Btn1Action,
  Btn2text,
  Btn2Action,
}: any) => {
  return (
    <Container className="mt-2">
      <Card>
        <Card.Header className="themeError colorNavy">
          {errorTitle ?? "Warning"}
        </Card.Header>
        <Card.Text className="colorNavy m-3">
          {errorMessage ?? "Something went wrong"}
        </Card.Text>
        {Btn1text && Btn1Action && (
          <Row className="mb-2 mx-1">
            <Col className="col-lg-2">
              <Button variant="primary" onClick={Btn1Action} className="w-100">
                {Btn1text}
              </Button>
            </Col>
            {Btn2text && Btn2Action && (
              <Col className="col-lg-2">
                <Button
                  variant="secondary"
                  onClick={Btn2Action}
                  className="w-100"
                >
                  {Btn2text}
                </Button>
              </Col>
            )}
          </Row>
        )}
      </Card>
    </Container>
  );
};

export default GenericErrorComponent;
