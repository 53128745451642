import axiosInstance from "../Constants/Instance";
import { withDrawStudent } from "../Constants/BaseURL";
import { returnDate } from "../../Utilities/DateUtilities";

// MANAGING THE LOADING AND ERROR STATES IN THE ACTION AS WELL
export const withdrawStudent = async (
  studentId: number,
  withdrawalDate: Date,
  setDecision: any,
  setError: any,
  setLeading: any
) => {
  try {
    debugger;
    const { data } = await axiosInstance.get(
      withDrawStudent + studentId + "/" + returnDate(withdrawalDate)
    );

    try {
      if (data.msg === "Withdrawal date is invalid") {
        setDecision(false);
        setError(
          "Students has more than 5 days of placement after this withdrawal date. Select a more current date."
        );
        setLeading(false);
        return;
      }
    } catch (error) {}

    setDecision(true);
    setError("");
    setLeading(false);
  } catch (e) {
    setDecision(false);
    setLeading(false);
    setError("Something went wrong. Please try again later");
  }
};
