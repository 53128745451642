import React, { useEffect, useState } from "react";
import Layout from "../Layout/Index";
import "react-datepicker/dist/react-datepicker.css";
import SearchFormWithdrawl from "../Components/SearchFormWithdrawl";
import SearchResultWithdrawl from "../Components/SearchResultWithdrawl";
import { searchWVStudentAction } from "../Store/Actions/searchWVStudentAction";
import { IStudent } from "../Models/IStudents";
import { searchWithdrawlParameters } from "../Utilities/Constants";
import SearchError from "../Components/SearchNoStudentFound";

const Withdrawl = () => {
  const breadcrumb: any = [
    { title: "Home", link: "/home" },
    { title: "withdrawal", link: `/Withdrawl` },
  ];
  const [studentList, setStudentList] = useState<IStudent[]>([]);
  // SEARCH QUERY FOR SEARCH STD API AND ITS LOADING/ERROR STATES
  const [searchQuery, setSearchQuery] = useState<any>({});
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResultError, setSearchResultError] = useState("");

  const searchStudentWithdrawl = () => {
    setSearchLoading(true);
    setStudentList([]);
    setSearchResultError("");
    searchWVStudentAction(
      { ...searchQuery },
      setStudentList,
      setSearchLoading,
      setSearchResultError
    );
  };

  return (
    <Layout breadcrumList={breadcrumb} pageTitle="withdrawal" IconNo="5">
      <SearchFormWithdrawl
        searchWithdrawlParameters={searchWithdrawlParameters}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        searchStudentWithdrawl={searchStudentWithdrawl}
        loading={searchLoading}
      ></SearchFormWithdrawl>
      {searchResultError ? <SearchError error={searchResultError} /> : <></>}
      {studentList.length ? (
        <SearchResultWithdrawl studentList={studentList} />
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default Withdrawl;
