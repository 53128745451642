import { useState } from "react";
import Layout from "../Layout/Index";
import SearchForm from "../Components/SearchForm";
import { IStudent } from "../Models/IStudents";
import { searchParameters } from "../Utilities/Constants";
import SearchResult from "../Components/SearchResult";
import Spinner from "react-bootstrap/Spinner";
import { searchStudentAction } from "../Store/Actions/searchStudentAction";
import SearchError from "../Components/SearchNoStudentFound";
import CbeStudentCheck from "../Components/CbeStudentCheck";
import { Container } from "react-bootstrap";

const Home = () => {
  // SEARCH RESULT WILL BE STORED HERE
  const [studentList, setStudentList] = useState<IStudent[]>([]);
  // SEARCH QUERY FOR SEARCH STD API AND ITS LOADING/ERROR STATES
  const [searchQuery, setSearchQuery] = useState<any>({});
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResultError, setSearchResultError] = useState("");
  const [isCBEStudent, setIsCBEStudent] = useState(false);

  // BREADCRUMB FOR LAYOUT
  const breadcrumb: any = [{ title: "Home", link: "/home" }];

  const searchStudent = (dob: any, setValidations: any) => {
    const tempValidation: any = {};
    // VALIDATING firstName and lastname
    if (searchQuery.firstName && !searchQuery.lastName) {
      tempValidation.lastName = "Last name is required";
      setValidations(tempValidation);
      // rerenderComponent();
      return;
    } else if (!searchQuery.firstName && searchQuery.lastName) {
      tempValidation.firstName = "First name is required";
      setValidations(tempValidation);
      // rerenderComponent();
      return;
    }
    setSearchLoading(true);
    setStudentList([]);
    setSearchResultError("");
    searchStudentAction(
      { ...searchQuery, dob },
      setStudentList,
      setSearchLoading,
      setSearchResultError
    );
  };

  const decideIfCbe = () => {
    setIsCBEStudent(true);
  };

  const cancelSearch = () => {
    setIsCBEStudent(false);
  };

  return (
    <Layout
      breadcrumList={breadcrumb}
      pageTitle="Student Enrollment"
      IconNo="2"
    >
      {isCBEStudent ? (
        <Container>
          <SearchForm
            searchParameters={searchParameters}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchStudent={searchStudent}
            cancelSearch={cancelSearch}
          />
          {searchLoading ? (
            <div className="d-flex justify-content-center my-3 py-3">
              <Spinner animation="border" variant="info" />{" "}
            </div>
          ) : searchResultError ? (
            <SearchError error={searchResultError} />
          ) : (
            <></>
          )}
          {studentList.length ? (
            <SearchResult studentList={studentList} />
          ) : (
            <></>
          )}
        </Container>
      ) : (
        <CbeStudentCheck decideIfCbe={decideIfCbe} />
      )}
    </Layout>
  );
};

export default Home;
