import React, { useState, useEffect } from "react";
import { Row, Col, Card, Spinner, Button } from "react-bootstrap";
import ReconciliationResult from "../Components/ReconciliationResult";
import {
  getReconciliations,
  saveReconciliation,
} from "../Store/Actions/ReconciliationActions";
import Layout from "../Layout/Index";
import { useNavigate } from "react-router";

import "react-datepicker/dist/react-datepicker.css";
import { Container, Table } from "react-bootstrap";

const Reconciliation = () => {
  const navigation = useNavigate();
  // SEARCH RESULT WILL BE STORED HERE
  const [studentList, setStudentList] = useState<any[]>([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);

  const breadcrumb: any = [
    { title: "Home", link: "/home" },
    { title: "Student Reconciliation", link: `/Reconciliation` },
  ];

  const approveStd = (student: any) => {
    saveReconciliation(
      student.id,
      true,
      navigation,
      student.student.sirsCFirstName + " " + student.student.sirsCLastName,
      student.sirS_cStudentNumber
    );
  };

  const showDisapprovalMessage = (student: any) => {
    saveReconciliation(
      student.id,
      false,
      navigation,
      student.student.sirsCFirstName + " " + student.student.sirsCLastName,
      student.sirS_cStudentNumber
    );
    setShowMessage(true);
  };

  const refreshScreen = () => {
    setShowMessage(false);
    setSearchLoading(true);
    getReconciliations(setStudentList, setSearchLoading);
  };

  useEffect(() => {
    setSearchLoading(true);
    getReconciliations(setStudentList, setSearchLoading);
  }, []);

  return (
    <Layout breadcrumList={breadcrumb} pageTitle="Reconciliation" IconNo="4">
      {showMessage ? (
        <Container>
          <Card>
            <Card.Header className="bgBlue50 colorNavy">Disappoved</Card.Header>
            <Row className="p-2">
              <Col>
                This account will be checked tomorrow as well for a possible
                match with PowerSchool. For the time being, the student will use
                the Westview account.
              </Col>
            </Row>
            <Col className="col-sm-6 col-lg-2 px-2 mb-2">
              <Button onClick={() => refreshScreen()}>Okay</Button>
            </Col>
          </Card>
        </Container>
      ) : searchLoading ? (
        <div className="d-flex justify-content-center my-3 py-3">
          <Spinner animation="border" variant="info" />{" "}
        </div>
      ) : (
        <ReconciliationResult
          studentList={studentList}
          approveStd={approveStd}
          showDisapprovalMessage={showDisapprovalMessage}
        />
      )}
    </Layout>
  );
};

export default Reconciliation;
