import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import Button from "react-bootstrap/esm/Button";
import DatePicker from "react-datepicker";

const SearchForm = ({
  searchParameters,
  searchQuery,
  searchStudent,
  setSearchQuery,
  cancelSearch,
}: any) => {
  const [startDate, setStartDate] = useState<any>("");
  const [validations, setValidations] = useState<any>();
  const [triggerRerender, seTriggerRerender] = useState<boolean>(false);
  const [btnDisable, sebtnDisable] = useState<boolean>(true);

  useEffect(() => {
    if (
      searchQuery.firstName ||
      searchQuery.lastName ||
      searchQuery.cbeNo ||
      searchQuery.asnNo ||
      startDate
    )
      sebtnDisable(false);
    else sebtnDisable(true);
  }, [triggerRerender]);

  return (
    <Container>
      <Card>
        <Card.Header className="bgBlue50 colorNavy">
          Please enter the student information that will be used to enroll the
          student
        </Card.Header>
        <Form className="p-2">
          <Row>
            {searchParameters.map((p: any, index: any) => {
              return (
                <Col key={index} className="col-sm-12 col-lg-4 mb-2">
                  <Form.Group className="mb-2" controlId="formBasicCBEID">
                    <Form.Label>
                      {p.Label}
                      {p.required ? " *" : ""}
                    </Form.Label>
                    <Form.Control
                      name={p.Value}
                      type="text"
                      placeholder={p.PlaceHolder}
                      onChange={(event) => {
                        let temp = searchQuery;
                        temp[event.target.name] = event.target.value;
                        setValidations(null);
                        setSearchQuery(temp);
                        seTriggerRerender(!triggerRerender);
                      }}
                    />
                  </Form.Group>
                  {/* SHOWING VALIDATION IF ANY */}
                  <Row className="px-3 text-danger">
                    {validations ? validations[p.Value] : ""}
                  </Row>
                </Col>
              );
            })}
            <Col>
              <Form.Group className="mb-3" controlId="formBasicDOB">
                <Form.Label>Date of Birth</Form.Label>
                <DatePicker
                  className="form-control"
                  selected={startDate}
                  onChange={(date: any) => {
                    seTriggerRerender(!triggerRerender);
                    setStartDate(date);
                  }}
                />
              </Form.Group>
            </Col>
            <Col className="d-flex align-items-end mb-3">
              <Button
                variant="primary"
                onClick={() => searchStudent(startDate, setValidations)}
                type="button"
                disabled={btnDisable}
                className="w-50"
              >
                Next
              </Button>
              <Button
                variant="primary"
                onClick={() => cancelSearch()}
                type="button"
                className="w-50 cancelBtn"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </Container>
  );
};

export default SearchForm;
