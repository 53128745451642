import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/esm/Card";
import { IStudent } from "../Models/IStudents";
import StudentWithdrawlComfirmation from "./StudentWithdrawlCheck";
import { withdrawStudent } from "../Store/Actions/studentWithdrawalActions";

interface ISearchResults {
  studentList: IStudent[];
}

const SearchResultWithdrawl = ({ studentList }: ISearchResults) => {
  const [selectedStudent, setSelectedStudent] = useState<IStudent>();
  const [startWithdrawl, setStartWithdrawl] = useState(false);
  const [decision, setDecision] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleWithdrawStudent = (student: IStudent) => {
    setSelectedStudent(student);
    setStartWithdrawl(true);
  };

  const decideIfWithdraw = (decision: boolean, withdrawalDate: Date) => {
    if (!decision) setStartWithdrawl(false);
    else {
      setError("");
      setLoading(true);
      withdrawStudent(
        selectedStudent?.studentId ?? 0,
        withdrawalDate,
        setDecision,
        setError,
        setLoading
      );
    }
  };

  return (
    <Container className="my-2">
      {startWithdrawl ? (
        decision ? (
          <div>
            {`${selectedStudent?.firstName} ${selectedStudent?.lastName}${
              selectedStudent?.cbeNo ? " CBE #: " + selectedStudent?.cbeNo : ""
            }${
              selectedStudent?.ablNo ? " ASN #: " + selectedStudent?.ablNo : ""
            } was successfully released from Westview.  For the time being, this account will remain disabled. 
            If this student moves to any other CBE school, the account will automatically be moved to the new school. 
            The account will no longer be restricted as per Westview's protocol.`}
          </div>
        ) : (
          <StudentWithdrawlComfirmation
            selectedStudent={selectedStudent}
            decideIfWithdraw={decideIfWithdraw}
            loading={loading}
            error={error}
          ></StudentWithdrawlComfirmation>
        )
      ) : (
        <Card>
          <Card.Header className="bgBlue50 colorNavy">
            Search Results
          </Card.Header>
          <Card.Text className="colorNavy m-1 mx-3">
            The following active CBE student(s) match/es the provided
            information.
          </Card.Text>
          <Table bordered hover>
            <thead>
              <tr>
                <th>Student No</th>
                <th>CBE No</th>
                <th>Student Name</th>
                <th>Gender</th>
                <th>DOB</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {studentList.map((item: IStudent, index: any) => {
                return (
                  <tr key={index}>
                    <td>{item.studentId}</td>
                    <td>{item.cbeNo}</td>
                    <td>
                      {item.firstName} {item.lastName}
                    </td>
                    <td>
                      {item.gender == "M"
                        ? "Male"
                        : item.gender == "F"
                        ? "Female"
                        : "Other"}
                    </td>
                    <td>{item.doBstr}</td>
                    <td>
                      <Button
                        variant="primary"
                        onClick={() => handleWithdrawStudent(item)}
                      >
                        Withdraw
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
      )}
    </Container>
  );
};

export default SearchResultWithdrawl;
