// FORM PARAMETERS FOR NEW STUDENT PAGE
const AddStudentFormAttributes: any = [
  {
    required: false,
    type: "text",
    widthL: "4",
    name: `cbeNo`,
    label: "CBE ID #",
    placeholder: "Enter CBE ID",
  },
  {
    required: false,
    type: "text",
    widthL: "4",
    name: "ablNo",
    label: "ASN ID #",
    placeholder: "Enter ASN ID",
  },
  {
    required: false,
    type: "text",
    widthL: "4",
    name: "currentStatus",
    label: "Current Status",
    defaultVal: "Current",
    readonly: true,
    placeholder: "Enter Current Status",
  },
  {
    required: true,
    type: "text",
    widthL: "4",
    name: "lastName",
    label: "Last Name",
    placeholder: "Enter Last Name",
  },
  {
    required: true,
    type: "text",
    widthL: "4",
    name: "firstName",
    label: "First Name",
    placeholder: "Enter First Name",
  },
  {
    required: false,
    type: "text",
    widthL: "4",
    name: "middleName",
    label: "Middle Name",
    placeholder: "Enter Middle Name",
  },
  {
    required: true,
    type: "Date",
    widthL: "3",
    name: "dob",
    label: "DOB",
    placeholder: "Enter DOB",
  },
  {
    required: false,
    type: "number",
    widthL: "2",
    name: "age",
    label: "Age",
    readonly: true,
    placeholder: "Age",
  },
  {
    required: true,
    type: "text",
    widthL: "2",
    readonly: false,
    name: "grade",
    label: "Grade",
    placeholder: "Enter Grade",
  },
  {
    name: "gender",
    label: "Gender",
    required: true,
    type: "Dropdown",
    widthL: "3",
    options: [
      { option: "Male", value: "M" },
      { option: "Female", value: "F" },
      { option: "Other", value: "O" },
    ],
  },
  {
    required: false,
    type: "Checkbox",
    widthL: "2",
    valTrue: "Y",
    valFalse: "N",
    name: "is3PlusPlan",
    label: "3 Plus Plan:",
  },
  {
    name: "province",
    label: "Province",
    required: true,
    type: "Dropdown",
    widthL: "6",
    addNew: true,
    options: [],
  },
  {
    name: "city",
    label: "City",
    required: true,
    type: "Dropdown",
    widthL: "6",
    addNew: true,
    options: [],
  },
  {
    required: false,
    type: "number",
    widthL: "2",
    defaultVal: 0,
    readonly: true,
    name: "eDay",
    label: "E Inst. Day No",
    placeholder: "Enter E Inst. Day No",
  },
  {
    required: false,
    type: "number",
    widthL: "2",
    defaultVal: 136,
    readonly: true,
    name: "tDay",
    label: "T Inst. Day No",
    placeholder: "Enter T Inst. Day No",
  },

  {
    required: false,
    type: "Date",
    widthL: "3",
    name: "eDate",
    label: "Enrollment Date",
    defaultVal: new Date(),
    readonly: true,
    placeholder: "Enter Enrollment Date",
  },
  {
    name: "unit",
    label: "Unit",
    required: true,
    type: "Dropdown",
    widthL: "2",
    options: [],
  },

  {
    required: false,
    type: "Date",
    widthL: "3",
    name: "wDate",
    readonly: true,
    label: "Withdrawal Date",
    placeholder: "Enter Withdrawal Date",
  },
];

export default AddStudentFormAttributes;
