import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Dropdown,
  InputGroup,
  Container,
  Spinner,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import Layout from "../Layout/Index";
import { useNavigate, useParams } from "react-router";
import {
  NewStudentLocalStorage,
  PrivincesLocalStorage,
  UnitsLocalStorage,
  TotalInstDaysNoLocalStorage,
} from "../Utilities/Constants";
import AddStudentFormAttributes from "../Utilities/newStudentFormAttributes";
import "react-datepicker/dist/react-datepicker.css";
import { registerStudentAction } from "../Store/Actions/registerStudentAction";
import { getCitiesAction } from "../Store/Actions/addStudentFormDataActions";
import {
  getAgeFromDOB,
  getYYYYMMDD,
  returnDate,
} from "../Utilities/DateUtilities";

const NewStudent = () => {
  const navigation = useNavigate();
  // STUDENT ID FROM PARAMS
  const { id } = useParams();
  // NEW STUDENT STATE
  const [newStudent, setNewStudent] = useState<any>({});
  // LOADING AND ERROR STATES FOR ADD STUDENT API
  const [registerStudentLoading, setRegisterStudentLoading] =
    useState<boolean>(false);
  const [registerStudentError, setRegisterStudentError] = useState<string>("");
  // USED TO RERENDER THE COMPONENT WHEN NEW STUDENT STATE IS UPDATED AS IT IS AN OBJECT AND SOMETIMES WHEN OBJECTS ARE UPDATED REACT DOES NOT RECOGNIZE IT AND DOES NOT RE RENDER THE COMPONENT
  const [rerender, setRerender] = useState<boolean>(false);
  // ALL TAGS FOR NEW STUDENT FROM WILL BE POPULATED FROM THIS STATE
  const [studentAttributes, setStudentAttributes] = useState<any>(
    AddStudentFormAttributes
  );
  // VALUES OF TEXTBOXES FOR ALL DROPDOWNS WITH ADD NEW FEATURE IS STORED IN THIS STATE
  const [DDAddNew, setDDAddNew] = useState<any>({});
  // ERROR MESSAGES FOR ALL FIELDS WILL BE STORED HERE
  const [validations, setValidations] = useState<any>({});
  const [cities, setCities] = useState<any>([]);

  // BREADCRUMB FOR LAYOUT
  const breadcrumb: any = [
    { title: "Home", link: "/home" },
    { title: "Add Student", link: `/NewStudent/${id}` },
  ];

  // GENERIC FUNCTION TO UPDATE VALUE AGAINT BINDING KEY IN NEW STUDENT
  const handleChangeValue = (e: any, name: any, value?: any) => {
    if (name == "province") {
      getCitiesAction(value, setCities);
    }
    let temp = newStudent;
    temp[name] = value ?? e?.target?.value;
    // IF USER HAD ENTERED SOME VALUE, REMOVE VALIDATION FOR THAT FIELD
    if (temp[name]) setValidations({ ...validations, [name]: "" });
    setNewStudent(temp);
    // TRIGGER RERENDER
    setRerender(!rerender);
  };

  // ADD NEW VALUE IN THE DROPDOWN
  const handleAddInDD = (name: string) => {
    let tempVal = DDAddNew[name];
    if (!tempVal) return;
    // CHANGE THE VALUE IN STUDENT OBJ
    handleChangeValue(null, name, tempVal);
    // CLICK THE DROPDOWN ON BUTTON CLICK
    document.getElementById(name + "dropdown")?.click();
  };

  const handleUpdatePropertiesOfNewStd = () => {
    let tempStd = JSON.parse(JSON.stringify(newStudent));
    if (tempStd.dob) tempStd.dob = returnDate(tempStd.dob);
    if (tempStd.wDate) tempStd.wDate = returnDate(tempStd.wDate);
    tempStd.eDate = getYYYYMMDD(new Date());
    tempStd.activeFlag = "A";
    if (!tempStd.is3PlusPlan) tempStd.is3PlusPlan = "N";
    tempStd.eDay = tempStd.eDay ? parseInt(tempStd.eDay) : 0;
    return tempStd;
  };

  const handleAddStudent = () => {
    // ALL MISSING FIELDS WILL BE PUSHED IN THIS ARRAY
    let missingField: any = [];
    // ADDING ERROR MESSAGES AGAINTS EACH FORM INPUT
    let tempValidation: any = {};
    studentAttributes.forEach((std: any) => {
      if (
        !newStudent[std.name] &&
        std.required &&
        newStudent[std.name] !== false
      ) {
        missingField.push(std.name);
        tempValidation[std.name] = `${std.label} is required.`;
      }
    });
    // SETTING ERRORS
    setValidations(tempValidation);
    // RETURN IF VALIDATION FAILS
    if (missingField.length) return;

    // SPINNER WILL BE TRIGGERED
    setRegisterStudentLoading(true);
    const updatedPropertiesOfNewStd = handleUpdatePropertiesOfNewStd();
    registerStudentAction(
      updatedPropertiesOfNewStd,
      setRegisterStudentLoading,
      setRegisterStudentError,
      navigation
    );
  };

  const handleReturnDDVal = (name: string, options: [any]) => {
    if (!newStudent[name]) return "Select";
    const selectedOpt = options.find((opt) => opt.value === newStudent[name]);
    return selectedOpt?.option ? selectedOpt.option : newStudent[name];
  };

  const handlePopulateOptions = (name: any, items: any) => {
    var attributes = studentAttributes;
    var itemDW = attributes.find((p: any) => p.name === name);
    itemDW.options = items ?? [];
    setStudentAttributes(
      studentAttributes.map((attr: any) => {
        return attr.name === name ? itemDW : attr;
      })
    );
  };

  const handlePopulateOptionsFromLS = (name: any, localStorageKey: any) => {
    let optionsList = localStorage.getItem(localStorageKey);
    if (optionsList != null) {
      var items = JSON.parse(optionsList);
    }
    handlePopulateOptions(name, items);
  };

  const handlePopulateValueFromLS = (
    name: any,
    LSKey: any,
    defaultVal: any
  ) => {
    const value = localStorage.getItem(LSKey);
    if (!value && !defaultVal) return;
    handleChangeValue(null, name, value ?? defaultVal);
  };

  useEffect(() => {
    handlePopulateOptionsFromLS("province", PrivincesLocalStorage);
    handlePopulateOptionsFromLS("unit", UnitsLocalStorage);
    handlePopulateValueFromLS("tDay", TotalInstDaysNoLocalStorage, 136);
  }, []);

  useEffect(() => {
    handlePopulateOptions("city", cities);
  }, [cities]);

  useEffect(() => {
    if (newStudent?.dob) {
      const age = getAgeFromDOB(newStudent?.dob);
      setNewStudent({ ...newStudent, age });
    }
  }, [newStudent?.dob]);

  useEffect(() => {
    // POPULATING NEW STUDENT'S PROPERTIES FROM LS IN CASE OF COMING FROM SEARCH STUDENT FORM
    let newStudentFromLS: any = localStorage.getItem(NewStudentLocalStorage);
    if (!newStudentFromLS) return;
    newStudentFromLS = JSON.parse(newStudentFromLS);
    if (
      newStudentFromLS?.studentId &&
      newStudentFromLS.studentId.toString() === id
    ) {
      setNewStudent({
        ...newStudent,
        ...newStudentFromLS,
        dob: new Date(newStudentFromLS.dob),
      });
    }
  }, [id]);

  return (
    <Layout breadcrumList={breadcrumb} pageTitle="Add Student" IconNo="3">
      <Card>
        <Card.Header className="bgBlue50 colorNavy">Add Student</Card.Header>
        <Form className="p-2">
          <Row>
            {/* RUNNING LOOP THROUGH STD ATTRIBUTES TO MAKE THE FORM */}
            {studentAttributes.map((properties: any, index: any) => {
              return (
                <Col
                  key={index}
                  className={`col-sm-12 col-lg-${properties.widthL} mb-2`}
                >
                  {/* CHECKING FOR TEXT/NUMBER/DATE AND CHECKBOX TOGEATHER AS THEIR CONFIG IS KIND OF SAME */}
                  {["text", "number", "Date", "Checkbox"].includes(
                    properties.type
                  ) ? (
                    <Form.Group className="mb-2" controlId="formBasicCBEID">
                      <Form.Label>
                        {properties.label}{" "}
                        <span className="RequiredStar">
                          {properties.required ? " *" : ""}
                        </span>
                      </Form.Label>
                      {["text", "number"].includes(properties.type) ? (
                        <Form.Control
                          type={properties.type}
                          placeholder={properties.placeholder}
                          readOnly={properties.readonly ?? false}
                          defaultValue={properties.defaultVal ?? ""}
                          value={newStudent[properties.name]}
                          // UPDATING BORDER COLOR IF THERE IS ANY ERROR AGAINST THIS FIELD
                          className={`${
                            validations[properties.name] ? "border-danger" : ""
                          }`}
                          onChange={(event) =>
                            handleChangeValue(event, properties.name)
                          }
                        />
                      ) : properties.type === "Date" ? (
                        <DatePicker
                          className="form-control"
                          selected={
                            newStudent[properties.name] ?? properties.defaultVal
                          }
                          readOnly={properties.readonly}
                          onChange={(date: any) =>
                            handleChangeValue(null, properties.name, date)
                          }
                        />
                      ) : (
                        <InputGroup.Checkbox
                          checked={
                            newStudent[properties.name] === properties.valTrue
                              ? true
                              : false
                          }
                          onChange={(event: any) =>
                            handleChangeValue(
                              null,
                              properties.name,
                              event?.target?.checked
                                ? properties.valTrue
                                : properties.valFalse
                            )
                          }
                          aria-label="Checkbox for following text input"
                        />
                      )}
                    </Form.Group>
                  ) : properties.type === "Dropdown" ? (
                    <Dropdown className="w-100">
                      <Form.Label>
                        {properties.label}
                        <span className="RequiredStar">
                          {properties.required ? " *" : ""}
                        </span>
                      </Form.Label>
                      <Dropdown.Toggle
                        className="bgcolorBlue w-100"
                        id={properties.name + "dropdown"}
                      >
                        {/* SHOWING SELECTED OPTION */}
                        {handleReturnDDVal(properties.name, properties.options)}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="w-100">
                        {/* IF ADD NEW IS ENABLED A TEXTFIELD WILL BE DISPLAYED WITH AN ADD BUTTON */}
                        {properties?.addNew ? (
                          <Container className="mb-2">
                            <Row>
                              <Col className="col-sm-8 col-lg-10">
                                <Form.Control
                                  placeholder="Add New"
                                  value={DDAddNew[properties.name]}
                                  onChange={(event) =>
                                    setDDAddNew({
                                      ...DDAddNew,
                                      [properties.name]: event.target.value,
                                    })
                                  }
                                />
                              </Col>
                              <Col className="col-sm-4 col-lg-2">
                                <Button
                                  className="w-100"
                                  onClick={() => handleAddInDD(properties.name)}
                                >
                                  Add
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        ) : (
                          <></>
                        )}
                        {/* DISPLAYING OPTIONS FOR DD */}
                        {properties.options.map((option: any, index: any) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() =>
                              handleChangeValue(
                                null,
                                properties.name,
                                option.value
                              )
                            }
                            className={`px-3${
                              index % 2 === 0 ? " bgBlue10" : ""
                            }`}
                          >
                            {option.option}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <></>
                  )}
                  {/* SHOWING VALIDATION IF ANY */}
                  <Row className="px-3 text-danger">
                    {validations[properties.name]}
                  </Row>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button variant="primary" onClick={() => handleAddStudent()}>
                {/* ADD STUDENT TEXT WILL BE REPLACED BY THE SPINNER */}
                {registerStudentLoading ? (
                  <Spinner
                    animation="border"
                    style={{ height: 20, width: 20 }}
                    className="mx-2"
                    variant="info"
                  />
                ) : (
                  <>Add Student</>
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </Layout>
  );
};

export default NewStudent;
