import axiosInstance from "../Constants/Instance";
import { studentById, reEnrolStudent } from "../Constants/BaseURL";
import {
  ReconciliationPwdLocalStorage,
  ShowPasswordLocalStorage,
} from "../../Utilities/Constants";
import { IStudent } from "../../Models/IStudents";
import {
  NewStudentPwdLocalStorage,
  NewStudentUsernameLocalStorage,
} from "../../Utilities/Constants";

// THIS ACTION WILL BE CALLED AS SOON AS THE RE-ENROL PAGE IS CLICKED
export const getStudentById = async (
  studentId: string,
  setStudent: any,
  setError: any,
  setloading: any
) => {
  try {
    const { data } = await axiosInstance.get(studentById + studentId);

    if (data?.studentId) setStudent(data);
    setloading(false);
    setError("");
  } catch (e) {
    setloading(false);
    setError("Student no found! Please try again.");
    console.log(e);
  }
};

// THIS ACTION WILL BE CALLED TO RE-ENROLL STUDENT
export const reEnrollStudentAction = async (
  student: any,
  navigation: any,
  setError: any,
  setloading: any
) => {
  try {
    const { data } = await axiosInstance.post(
      reEnrolStudent + `?studentId=${student.studentId}`,
      {}
    );
    debugger;
    localStorage.setItem(ShowPasswordLocalStorage, "Y");
    localStorage.setItem(NewStudentPwdLocalStorage, data.password);
    localStorage.setItem(NewStudentUsernameLocalStorage, data.username);
    navigation(`/Password/${data.username ? data.username : "0"}`);
    // navigation(
    //   `/Password/${student.studentId}/${
    //     student.firstName + " " + student.lastName
    //   }/${student.cbeNo}`
    //);
    setloading(false);
    setError("");
  } catch (e) {
    console.log(e);
    setloading(false);
    setError("Failed to re-enrol!");
  }
};
