import React from "react";
import { MdHelp, MdOutlinePassword } from "react-icons/md";
import { BsFillPersonFill } from "react-icons/bs";
import { AiOutlineForm } from "react-icons/ai";
import { RiLogoutBoxLine } from "react-icons/ri";
import { FaChevronRight, FaAddressCard } from "react-icons/fa";
import {
  MdOutlinePersonSearch,
  MdPersonRemoveAlt1,
  MdExitToApp,
} from "react-icons/md";
// INSTEAD OF IMPORTING EACH ICON IN EACH FILE WE ARE MANAGING ALL ICONS IN THE ICON DRAWER
const IconDrawer = ({ type, className }: any) => {
  switch (type) {
    case "1":
      return <MdOutlinePassword className={className} />;
    case "2":
      return <FaAddressCard className={className} />;
    case "3":
      return <AiOutlineForm className={className} />;
    case "4":
      return <MdOutlinePersonSearch className={className} />;
    case "5":
      return <MdPersonRemoveAlt1 className={className} />;
    case "6":
      return <MdExitToApp className={className} />;
    case "7":
      return <BsFillPersonFill className={className} />;
    case "8":
      return <FaAddressCard className={className} />;
    case "9":
      return <FaChevronRight className={className} />;
    case "10":
      return <RiLogoutBoxLine className={className} />;
    case "11":
      return <MdHelp className={className} />;
    default:
      return <></>;
  }
};

export default IconDrawer;
