import React from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router";

const SearchError = ({ error }: any) => {
  const navigate = useNavigate();
  return (
    <Container className="mt-2">
      <Card>
        <Card.Header className="themeError colorNavy">No match</Card.Header>
        <Card.Text className="colorNavy m-3">
          {/* IF SEARCH STUDENT API RETURNS NO STUDENT THEN error==="NoItem" OTHERWISE API'S CATCH BLOCK'S ERROR WOULD BE DISPLAYED */}
          {error === "NoItem"
            ? "We found no CBE active student match with the information you provided. Do you want to enroll this student in STARS now before registering them in PowerSchool?"
            : error}
        </Card.Text>
        {error === "NoItem" && (
          <Row className="mb-2 mx-1">
            <Col className="col-lg-2">
              <Button
                variant="primary"
                onClick={() => navigate("/newstudent/0")}
                className="w-100"
              >
                Yes
              </Button>
            </Col>
            <Col className="col-lg-2">
              <Button variant="secondary" className="w-100">
                No
              </Button>
            </Col>
          </Row>
        )}
      </Card>
    </Container>
  );
};

export default SearchError;
