import React from "react";
import { Card, Container, Button, Table } from "react-bootstrap";
import { returnDateFromDateTimeStr } from "../Utilities/DateUtilities";

const ReconciliationResult = ({
  studentList,
  approveStd,
  showDisapprovalMessage,
}: any) => {
  return (
    <Container className="my-2">
      <Card>
        <Card.Header className="bgBlue50 colorNavy">Search Results</Card.Header>
        <Card.Text className="colorNavy m-1 mx-3">
          {studentList.length
            ? "Ensure that each student(s) is reconciled by approving or denying.  By doing so, it ensures that all CBE students are using the correct account and that the information in STARS is up-to-date and matches with PowerSchool."
            : "No student found"}
        </Card.Text>
        {studentList?.length ? (
          <Table bordered hover>
            <thead>
              <tr>
                <th>Student ID</th>
                <th>CBE No</th>
                <th>WV Account</th>
                <th>Student Name</th>
                <th>DOB</th>
                <th colSpan={2}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {studentList
                //.map((student: any) => student?.student)
                //.filter((std: any) => std?.studentId)
                .map((student: any, id: any) => (
                  <tr key={student.id}>
                    <td>{student.student_id}</td>
                    <td>
                      {student.sirS_cStudentNumber
                        ? student.sirS_cStudentNumber
                        : "-"}
                    </td>
                    <td>
                      {student.student.wvAccountId
                        ? student.student.wvAccountId
                        : "-"}
                    </td>
                    <td>{`${student.student.sirsCFirstName} ${student.student.sirsCLastName}`}</td>
                    <td>
                      {returnDateFromDateTimeStr(
                        student.student.sirsDBirthDate
                      )}
                    </td>
                    <td>
                      <Button
                        onClick={() => approveStd(student)}
                        variant="primary"
                      >
                        Approve
                      </Button>
                    </td>
                    <td>
                      <Button
                        onClick={() => showDisapprovalMessage(student)}
                        variant="primary"
                      >
                        Disapprove
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : (
          <></>
        )}
      </Card>
    </Container>
  );
};

export default ReconciliationResult;
