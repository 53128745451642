import axiosInstance from "../Constants/Instance";
import { searchStudentApi } from "../Constants/BaseURL";
import { getYYYYMMDD } from "../../Utilities/DateUtilities";
import { returnStringWithText } from "../../Utilities/StringUtilities";

// MANAGING THE LOADING AND ERROR STATES IN THE ACTION AS WELL
export const searchStudentAction = async (
  { firstName, lastName, cbeNo, asnNo, dob }: any,
  setStudentList: any,
  setSearchLoading: any,
  setSearchResultError: any
) => {
  try {
    if (dob) {
    }
    //USING ES6 TEMPLATE STRING TO REMOTE cbeNo & asnNo IF THEY ARE NOT PROVIDED BY USER
    // CONVERTING JS DATE FORMAT INTO DD-MM-YYYY
    let url = "";
    [
      { str: firstName, text: "firstName=" },
      { str: lastName, text: "lastName=" },
      { str: cbeNo, text: "cbeNo=" },
      { str: asnNo, text: "asnNo=" },
      { str: getYYYYMMDD(dob), text: "DOB=" },
    ].forEach((obj) => {
      url =
        url +
        returnStringWithText({ ...obj, text: url ? "&" + obj.text : obj.text });
    });
    url = searchStudentApi + "?" + url;
    const { data } = await axiosInstance.get(url);
    if (data && Array.isArray(data) && data.length) setStudentList(data);
    else setSearchResultError("NoItem");
    setSearchLoading(false);
  } catch (e) {
    setSearchLoading(false);
    setSearchResultError("Something went wrong. Please try again later");
  }
};
