import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Spinner, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";

const StudentWithdrawlCheck = ({
  decideIfWithdraw,
  selectedStudent,
  loading,
  error,
}: any) => {
  const [withdrawalDate, setWithdrawalDate] = useState<any>();
  const handleChangeValue = (e: any, value?: any) => {
    setWithdrawalDate(value);
  };

  useEffect(() => {
    setWithdrawalDate(new Date());
  }, []);

  return (
    <Container className="mt-2">
      <Card>
        <Card.Header className="bgBlue50 colorNavy">CBE Student</Card.Header>
        <Card.Text className="colorNavy m-3">
          Are you sure if you want to release{" "}
          {selectedStudent.firstName + " " + selectedStudent.lastName}?
        </Card.Text>
        <Row className="mb-2 mx-1">
          <Col className="col-lg-4">
            Please change withdrawal date.
            <DatePicker
              selected={withdrawalDate}
              className="form-control"
              onChange={(date: any) => handleChangeValue(null, date)}
            />
          </Col>
        </Row>
        <Row className="mb-2 mx-1">
          <Col className="col-lg-2">
            <Button
              onClick={() => decideIfWithdraw(true, withdrawalDate)}
              variant="primary"
              className="w-100"
            >
              {loading ? (
                <Spinner
                  variant="info"
                  animation="border"
                  style={{ height: 20, width: 20 }}
                  className="mx-2"
                />
              ) : (
                <>Yes</>
              )}
            </Button>
          </Col>
          <Col className="col-lg-2">
            <Button
              variant="secondary"
              onClick={() => decideIfWithdraw(false, withdrawalDate)}
              className="w-100"
            >
              No
            </Button>
          </Col>
        </Row>
        <Row className="mb-2 mx-3 text-danger">{error}</Row>
      </Card>
    </Container>
  );
};

export default StudentWithdrawlCheck;
