// LOCALSTORAGE CONSTANTS
export const TotalInstDaysNoLocalStorage = "TotalInstDaysNoLocalStorage";
export const UnitsLocalStorage = "UnitsLocalStorage";
export const PrivincesLocalStorage = "PrivincesLocalStorage";
export const NewStudentLocalStorage = "NewStudentLocalStorage";
export const UserProfileLocalStorage = "UserProfileLocalStorage";
export const ShowPasswordLocalStorage = "ShowPasswordLocalStorage";
export const NewStudentPwdLocalStorage = "NewStudentPwdLocalStorage";
export const NewStudentUsernameLocalStorage = "NewStudentUsernameLocalStorage";
export const ReconciliationPwdLocalStorage = "ReconciliationPwdLocalStorage";
export const TokenSessionStorage = "TokenSessionStorage";

// HEADER OPTIONS
export const headerLinks = [
  { label: "Student Enrollment", link: "/home", iconNo: "2" },
  { label: "Student Withdrawal", link: "/withdrawl", iconNo: "5" },
  { label: "Student Reconciliation", link: "/reconciliation", iconNo: "4" },
  { label: "Back to Stars", link: "/home", iconNo: "6" },
  { label: "Help", link: "/home", iconNo: "11" },
];

// FORM PARAMETERS FOR WITHDRAWAL PAGE
export const searchWithdrawlParameters = [
  {
    required: false,
    Label: "CBE #",
    PlaceHolder: "Enter CBE #",
    Value: "cbeNo",
  },
  {
    required: false,
    Label: "ASN #",
    PlaceHolder: "Enter ASN #",
    Value: "asnNo",
  },
];

// FORM PARAMETERS FOR ENROLLMENT PAGE
export const searchParameters = [
  {
    Label: "First Name",
    required: false,
    PlaceHolder: "Enter First Name",
    Value: "firstName",
  },
  {
    Label: "Last Name",
    required: false,
    PlaceHolder: "Enter Last Name",
    Value: "lastName",
  },
  {
    required: false,
    Label: "CBE #",
    PlaceHolder: "Enter CBE #",
    Value: "cbeNo",
  },
  {
    required: false,
    Label: "ASN #",
    PlaceHolder: "Enter ASN #",
    Value: "asnNo",
  },
];
